/**
 * Renders Resources page
 *
 * @flow
 */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { paths } from '../../config';
import Directory from '../components/Directory';

function Resources() {
  const data = useStaticQuery(graphql`
    query {
      page: wordpressPage(path: {eq: "/resources/"}) {
        ...DirectoryPage
      }
      allItems: allWordpressWpResources(sort: {fields: date, order: DESC}) {
        edges {
          node {
            id: wordpress_id
            title
            categories: resource_categories {
              slug
            }
            tags: resource_tags {
              name
            }
            acf {
              order: display_order
              description: resource_description
              website: resource_website_url
            }
            featuredImage: featured_media {
              ...FeaturedImage
            }
          }
        }
      }
      allCategories: allWordpressWpResourceCategories(sort: {fields: acf___display_order, order: DESC}, filter: {count: {gt: 0}}) {
        edges {
          node {
            id: wordpress_id
            name
            slug
            description
            acf {
              order: display_order
              featuredImage: featured_image {
                ...FeaturedImage
              }
              tags {
                name
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Directory
      name="Resources"
      singularName="Resource"
      path={paths.resources}
      data={data}
    />
  );
}

export default Resources;
